import { Button, Checkbox, Input, Select, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { ColorPaletteParamTypeEnum } from '../../../model/enums/ColorPaletteParamTypeEnum';
import { DataProductTypeEnum } from '../../../model/enums/DataProductTypeEnum';
import {
  VisualisationTypeEnum,
  VisualisationTypeName,
} from '../../../model/enums/VisualisationTypeEnum';
import { useFileResults } from '../hooks/useFileResults';
import { useGetDataProductFiles } from '../hooks/useGetDataProductFiles';
import { useGetDataProductsPaletteTypes } from '../hooks/useGetDataProductsPaletteTypes';
import {
  ColorPaletteParameterGroupInterface,
  DataProductInterface,
  ParameterMappingInterface,
  VisualizationTypeInterface,
} from '../pages/Products';
import { DataProductFilesInterface } from './ValidationFiles';

function DynamicParameteres({
  form,
  setForm,
  setCheckParameters,
}: {
  form: DataProductInterface;
  setForm: React.Dispatch<React.SetStateAction<DataProductInterface>>;
  setCheckParameters: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isRefetching, setIsRefetching] = useState(false);
  const { data } = useGetDataProductFiles(isRefetching, form?.id);
  const { data: paletteTypes } = useGetDataProductsPaletteTypes(form?.productType);

  const doneIds = data?.reduce((accumulator: number[], d: DataProductFilesInterface) => {
    if (d.status === 'DONE') {
      accumulator.push(d.id);
    }
    return accumulator;
  }, []);

  const { data: filesData, isLoading } = useFileResults(doneIds ? doneIds : []);

  useEffect(() => {
    setIsRefetching(true);
  }, []);

  useEffect(() => {
    if (data) {
      setIsRefetching(false);
    }
  }, [data]);

  const columns = [
    {
      title: 'Parameter',
      dataIndex: 'paramType',
      key: 'paramType',
      render: (parameter: string, item: ParameterMappingInterface) => {
        if (item.default) {
          return <span>{parameter}</span>;
        } else {
          return (
            <Input value={parameter} onChange={(e) => handleParameter(e.target.value, item.id)} />
          );
        }
      },
    },
    {
      title: 'Parameter Type',
      dataIndex: 'colorPaletteParameterGroup',
      key: 'colorPaletteParameterGroup',
      render: (
        parameterType: ColorPaletteParameterGroupInterface,
        item: ParameterMappingInterface,
      ) => (
        <Select
          value={parameterType?.value}
          options={paletteTypes?.map((type: ColorPaletteParameterGroupInterface) => {
            return {
              value: type.value,
              label: type.name,
            };
          })}
          onChange={(e) => handleColorPalette(e, item)}
        />
      ),
    },
    {
      title: 'Interpolation',
      dataIndex: 'isInterpolationEnabled',
      key: 'isInterpolationEnabled',
      render: (isInterpolationEnabled: boolean, item: ParameterMappingInterface) => (
        <Checkbox checked={isInterpolationEnabled} onChange={(e) => handleInterpolation(e, item)} />
      ),
    },
    {
      title: 'Visualisation Type',
      dataIndex: 'visualizationTypes',
      key: 'visualizationTypes',
      render: (
        visualizationTypes: VisualizationTypeInterface[],
        item: ParameterMappingInterface,
      ) => {
        const visualisationTypes = visualizationTypes?.map(
          (visualizationType: VisualizationTypeInterface) => visualizationType.visualizationType,
        );
        return (
          <Select
            value={visualisationTypes}
            mode="multiple"
            options={Object.values(VisualisationTypeEnum).map((visualisation: string) => {
              return {
                value: visualisation,
                label: VisualisationTypeName[visualisation as VisualisationTypeEnum],
              };
            })}
            onChange={(e) => handleVisualisationType(e, item)}
          />
        );
      },
      hidden: form.productType !== 'MODEL',
    },
    {
      title: 'Default Visualisation Type',
      dataIndex: 'visualizationTypesDefault',
      key: 'visualizationTypesDefault',
      render: (_: any, item: ParameterMappingInterface) => {
        const defaultType = item.visualizationTypes?.find(
          (vt: VisualizationTypeInterface) => vt.isDefault,
        );
        return (
          <Select
            value={defaultType?.visualizationType}
            options={item.visualizationTypes?.map((visualisation: VisualizationTypeInterface) => {
              return {
                value: visualisation.visualizationType,
                label:
                  VisualisationTypeName[
                    visualisation.visualizationType.toLowerCase() as VisualisationTypeEnum
                  ],
              };
            })}
            onChange={(e) => handleDefaultVisualisation(e, item)}
          />
        );
      },
      hidden: form.productType !== 'MODEL',
    },
    {
      title: '',
      render: (_: any, item: ParameterMappingInterface) => {
        return (
          <>
            {!item.default && (
              <Button danger shape="round" onClick={() => handleDelete(item.id)}>
                Delete
              </Button>
            )}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    const filteredArray =
      filesData
        ?.map((item1: any) => {
          const matchingItem = form.parameterMappings.find(
            (item2: ParameterMappingInterface) => item2.paramType === item1.parameter,
          );

          return {
            id: v4(),
            default: true,
            paramType: item1.parameter,
            ...(matchingItem ? matchingItem : {}),
          };
        })
        .filter((obj, index, array) => {
          return array.findIndex((o) => o.paramType === obj.paramType) === index;
        }) || [];

    const unmatchedItems =
      form.parameterMappings
        .filter(
          (item1: ParameterMappingInterface) =>
            !filesData?.find((item2: any) => item1.paramType === item2.parameter),
        )
        .map((unmatchedItem: ParameterMappingInterface) => ({
          id: v4(),
          default: false,
          ...unmatchedItem,
        })) || [];
    setForm({ ...form, parameterMappings: [...filteredArray, ...unmatchedItems] });
  }, [filesData]);

  const handleColorPalette = (e: ColorPaletteParamTypeEnum, item: ParameterMappingInterface) => {
    const type = paletteTypes.find(
      (paletteType: ColorPaletteParameterGroupInterface) => paletteType.value === e,
    );
    const data = form.parameterMappings.map((parameterMapping: ParameterMappingInterface) => {
      if (parameterMapping.id === item.id) {
        return { ...parameterMapping, colorPaletteParameterGroup: type };
      } else {
        return parameterMapping;
      }
    });
    setForm({ ...form, parameterMappings: [...data] });
  };

  const handleInterpolation = (e: CheckboxChangeEvent, item: ParameterMappingInterface) => {
    const data = form.parameterMappings.map((i: ParameterMappingInterface) => {
      if (i.id === item.id) {
        return { ...i, isInterpolationEnabled: e.target.checked };
      } else {
        return i;
      }
    });
    setForm({ ...form, parameterMappings: [...data] });
  };

  const handleVisualisationType = (e: VisualisationTypeEnum[], item: ParameterMappingInterface) => {
    const data = form.parameterMappings.map((i: ParameterMappingInterface) => {
      if (i.id === item.id) {
        const visualizationTypes = e.map((visualisation, index) => {
          return { visualizationType: visualisation, isDefault: index === 0 ? true : false };
        });
        return { ...i, visualizationTypes: [...visualizationTypes] };
      } else {
        return i;
      }
    });
    setForm({ ...form, parameterMappings: [...data] });
  };

  const handleDefaultVisualisation = (
    e: VisualisationTypeEnum,
    item: ParameterMappingInterface,
  ) => {
    const data = form.parameterMappings.map((i: ParameterMappingInterface) => {
      if (i.id === item.id) {
        const visualizationTypes = item.visualizationTypes?.map(
          (visualization: VisualizationTypeInterface) => {
            if (visualization.visualizationType === e) {
              return { ...visualization, isDefault: true };
            } else {
              return { ...visualization, isDefault: false };
            }
          },
        );
        if (visualizationTypes) {
          return { ...i, visualizationTypes: [...visualizationTypes] };
        } else {
          return i;
        }
      } else {
        return i;
      }
    });
    setForm({ ...form, parameterMappings: [...data] });
  };

  const handleParameter = (value: string, id?: string) => {
    const updatedArray = form.parameterMappings.map((obj) => {
      if (obj.id === id) {
        return { ...obj, paramType: value };
      }
      return obj;
    });
    setForm({ ...form, parameterMappings: [...updatedArray] });
  };

  const handleDelete = (id?: string) => {
    const data = form.parameterMappings.filter((item) => item.id !== id);
    setForm({ ...form, parameterMappings: [...data] });
  };

  const addRow = () => {
    let newRow;
    if (form.productType === DataProductTypeEnum.SATELLITE) {
      newRow = {
        id: v4(),
        paramType: '',
        default: false,
        visualizationTypes: [{ isDefault: true, visualizationType: VisualisationTypeEnum.HEATMAP }],
      };
    } else {
      newRow = {
        id: v4(),
        paramType: '',
        default: false,
      };
    }
    setForm({ ...form, parameterMappings: [...form.parameterMappings, newRow] });
  };

  useEffect(() => {
    if (form.parameterMappings?.length > 0) {
      const checkParameters = form.parameterMappings.some(
        (parameterMapping) =>
          !parameterMapping.paramType ||
          !parameterMapping.visualizationTypes ||
          parameterMapping.visualizationTypes.length === 0 ||
          !parameterMapping.colorPaletteParameterGroup,
      );
      setCheckParameters(checkParameters);
    }
  }, [form.parameterMappings]);

  return (
    <>
      {!isLoading && <Button onClick={addRow}>Add Parameter</Button>}
      <Table
        dataSource={form.parameterMappings}
        columns={columns}
        rowKey="id"
        pagination={false}
        loading={isLoading}
      />
    </>
  );
}

export default DynamicParameteres;
